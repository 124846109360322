.link {
  text-decoration: none;
  margin-left: 3%;
  color: rgb(0, 158, 220);
  font-size: 15px;
  font-weight: 400;
}

.footer {
  display: flex;
  gap: 3rem;
  font-size: 20px;
  padding-top: 30px;
  flex-wrap: wrap;
}

.box1 {
  margin-top: 20px;
  width: 100%;
  height: 300px;
  text-align: center;
  background-color: #5fa0df;
  padding-top: 50px;
  font-size: 15px;
  color: rgb(247, 255, 244);
}

.text {
  margin-top: 10px;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.inputBox {
  border-radius: 5px;
  padding: 10px 30px;
}
.btn {
  padding: 10px 15px;
  color: rgb(255, 233, 231);
  font-weight: 600;
  background: rgb(0, 113, 194);
  border-radius: 5px;
}

@media screen and (max-width: 900px) {
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.box2 {
  width: 100%;
  background: rgb(0, 53, 128);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.btn1 {
  background-color: rgb(0, 53, 128);
  color: rgb(255, 223, 206);
  border: 1px solid white;
}

.box3 {
  width: 100%;
  background: rgb(0, 53, 128);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-top: 1px solid white;
  gap: 1.5rem;
}

.a {
  color: white;
}

.text2 {
  margin-top: 50px;
  text-align: center;
}

.h {
  color: rgb(90, 115, 194);
  font-size: 20px;
  font-weight: 600;
}

.p {
  font-size: 12px;
}

.logo {
  display: flex;
  gap: 2rem;
}
